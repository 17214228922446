gsap.registerPlugin(ScrollTrigger);

gsap.utils.toArray('.image-container').forEach(function (container) {
    let image = container.querySelector('img');

    gsap.to(image, {
        y: () => image.offsetHeight - container.offsetHeight,
        ease: 'none',
        scrollTrigger: {
            trigger: container,
            scrub: true,
            pin: false,
            // markers: true,
            invalidateOnRefresh: true,
        },
    });
});
